<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">樣板管理</h2>
      <!-- <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div> -->
      <!-- <div class="grid grid-cols-2 divide-x divide-black-800">
        <div>
          <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="SendNewWeb">
            網站改版
          </button>
        </div>
        <div>
          <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <div class="xl:flex sm:mr-auto">
            <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
              <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model="testEmail"
                placeholder="請輸入mail" />
            </div>
            <div class="mt-2 xl:mt-0">
              <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="SendTest">
                測試
              </button>
            </div>
          </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @edit="onGridEdit" @removeSelectedRows="hideBatchDropDown()">
          <template #modal="{ row, submit, reset }">
            <vxe-form ref="form" v-bind="formOptions" :data="row" @reset="reset" @submit="submit">
              <template #html-editer="{ data }">
                <!-- <p class="mb-2">{{ data.TemplateDescription }}</p> -->
                <CKEditor v-model="data.Content" v-bind="htmlEditorOptions" />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認"
              @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
            </vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox" class="input input--switch border mt-4 ml-2" :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)" />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{ option.label }}
                </div>
                <input v-model.trim="option.data" :disabled="!option.checked" type="text"
                  class="input pl-12 w-full border col-span-4" placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)" />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    const gridOptions: GridOptions = {
      canCreate: false,
      canDelete: false,
      multiselect: false,
      stripe: false,
      title: '樣板',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      columns: [{
        field: 'Name',
        title: '名稱',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      { field: "ModelType", title: "類型", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.ModelType).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      promises: {
        query: model ? (params) => {
          var list = [21,55,52,53,5,6,10,54,44]; //,56
          params.condition = new Condition("ModelType", Operator.In, list)
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: "ModelType", order: 0 });
          }
          return model.dispatch('template/query', params)
        } : undefined,
        save: model ? (params) => model.dispatch('template/save', params) : undefined
      },
      modalConfig: { showFooter: true, width: "80%", height: "80%" }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        }, {
          field: "ModelType",
          title: "類型",
          span: 12,
          itemRender: {
            name: "$select",
            props: { disabled: true },
            options: model ? Object.values(model.enums.ModelType).map(e => { return { label: e.Name, value: e.Value } }) : []
          }
        }, { field: 'Content', span: 24, slots: { default: 'html-editer' } },
      ],
      rules: {
        Name: [{ type: 'string', required: true }]
      }
    }

    const htmlEditorOptions = {
      editor: ClassicEditor,
      config: {
        extraPlugins: [UploadAdapterPlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    }

    const SendNewWeb = () => {
      if(confirm("確定要寄出網站改版信件?")) {
        model?.dispatch('template/send', 9).then(() => {
          CloudFun.send("info", {
            subject: "執行成功",
            content: "已發行完成",
          });
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        })        
      }
    }

    const testEmail = ref<any>('');

    const SendTest = () => {
      if(testEmail.value == '' || testEmail.value == null) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "請輸入mail",
        });
        return;
      }
      if(confirm("確定要寄出測試信件?")) {
        model?.dispatch('template/sendTest', { type: 9, mail: testEmail.value }).then(() => {
          CloudFun.send("info", {
            subject: "執行成功",
            content: "已發行完成",
          });
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        })        
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      htmlEditorOptions,
      SendNewWeb,
      testEmail,
      SendTest,
    }
  },
  methods: {
    hideBatchDropDown() {
      cash('#batch-dropdown').dropdown('hide')
    },
    async onGridEdit(row: any, callback: any) {
      row.Content = row.Content || '';
      row.TemplateDescription = await this.$model.dispatch("template/getDescription", row.ModelType);
      callback();
    }
  }
})
</script>
<style scope>
.ck.ck-balloon-panel {
  z-index: 2000;
}
</style>
