
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    const gridOptions: GridOptions = {
      canCreate: false,
      canDelete: false,
      multiselect: false,
      stripe: false,
      title: '樣板',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      columns: [{
        field: 'Name',
        title: '名稱',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      { field: "ModelType", title: "類型", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.ModelType).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      promises: {
        query: model ? (params) => {
          var list = [21,55,52,53,5,6,10,54,44]; //,56
          params.condition = new Condition("ModelType", Operator.In, list)
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: "ModelType", order: 0 });
          }
          return model.dispatch('template/query', params)
        } : undefined,
        save: model ? (params) => model.dispatch('template/save', params) : undefined
      },
      modalConfig: { showFooter: true, width: "80%", height: "80%" }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        }, {
          field: "ModelType",
          title: "類型",
          span: 12,
          itemRender: {
            name: "$select",
            props: { disabled: true },
            options: model ? Object.values(model.enums.ModelType).map(e => { return { label: e.Name, value: e.Value } }) : []
          }
        }, { field: 'Content', span: 24, slots: { default: 'html-editer' } },
      ],
      rules: {
        Name: [{ type: 'string', required: true }]
      }
    }

    const htmlEditorOptions = {
      editor: ClassicEditor,
      config: {
        extraPlugins: [UploadAdapterPlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    }

    const SendNewWeb = () => {
      if(confirm("確定要寄出網站改版信件?")) {
        model?.dispatch('template/send', 9).then(() => {
          CloudFun.send("info", {
            subject: "執行成功",
            content: "已發行完成",
          });
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        })        
      }
    }

    const testEmail = ref<any>('');

    const SendTest = () => {
      if(testEmail.value == '' || testEmail.value == null) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "請輸入mail",
        });
        return;
      }
      if(confirm("確定要寄出測試信件?")) {
        model?.dispatch('template/sendTest', { type: 9, mail: testEmail.value }).then(() => {
          CloudFun.send("info", {
            subject: "執行成功",
            content: "已發行完成",
          });
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        })        
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      htmlEditorOptions,
      SendNewWeb,
      testEmail,
      SendTest,
    }
  },
  methods: {
    hideBatchDropDown() {
      cash('#batch-dropdown').dropdown('hide')
    },
    async onGridEdit(row: any, callback: any) {
      row.Content = row.Content || '';
      row.TemplateDescription = await this.$model.dispatch("template/getDescription", row.ModelType);
      callback();
    }
  }
})
